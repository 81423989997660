import Alpine from "alpinejs";
import Keyboard from "simple-keyboard";

window.Alpine = Alpine;

const phrases = [
    'You only live once.', 
    '& nothing else matters.', 
    'Alors on Dance.', 
    'Welcome to the new age.', 
    'The dog days are over.', 
    'Dialectos de ternura.'
];

const dataScene = {
  screen: 0,
  background: 0,
  phrase: 0,
  text:'',

  init() {
    // this.$refs.phrase.innerHTML = phrases[this.phrase - 1];
    this.$refs.poster.classList.add("poster-" + this.background);
  },

  posterReset() {
    this.screen = 0;
    this.background = 0;
    this.phrase = 0;
  },

  setBackground(background) {
    this.background = background;

    this.$refs.poster.classList.remove('poster-1');
    this.$refs.poster.classList.remove('poster-2');
    this.$refs.poster.classList.remove('poster-3');
    this.$refs.poster.classList.remove('poster-4');
    this.$refs.poster.classList.remove('poster-5');
    this.$refs.poster.classList.remove('poster-6');

    this.$refs.poster.classList.add('poster-' + background);
  },

  setPhrase(phrase) {
    this.phrase = phrase;

    this.$refs.phrase.classList.remove('poster-phrase-1');
    this.$refs.phrase.classList.remove('poster-phrase-2');
    this.$refs.phrase.classList.remove('poster-phrase-3');
    this.$refs.phrase.classList.remove('poster-phrase-4');
    this.$refs.phrase.classList.remove('poster-phrase-5');
    this.$refs.phrase.classList.remove('poster-phrase-6');

    this.$refs.phrase.classList.add('poster-phrase-' + phrase);
  },

  setKeyboardInput(input) {
    this.text = input;
  },

  lastScreen() {
    this.screen = 4;

    const data = {
      poster: this.background,
      layer: this.phrase,
      phrase: this.text,
    };

    console.log(data);
    console.log('finish');
  }
};

Alpine.data("dataScene", () => dataScene);
Alpine.start();

const keyboard = new Keyboard({
  onChange: (input) => onChange(input),
  onKeyPress: (button) => onKeyPress(button),
});

function onChange(input) {
  document.querySelector(".userinput").innerHTML = input;
}

function onChange(input) {
  document.querySelector(".userinput").innerHTML = input;

  const event = new CustomEvent("keyboardinput", {
    // bubbles: true,
    // cancelable: true,
    detail: { input },
  });

  window.dispatchEvent(event);
}

function onKeyPress(button) {
  if (button === "{shift}" || button === "{lock}") handleShift();

  if (button === '{enter}') {
    window.dispatchEvent(new CustomEvent("endposter"));
  }
}

function handleShift() {
  let currentLayout = keyboard.options.layoutName;
  let shiftToggle = currentLayout === "default" ? "shift" : "default";

  keyboard.setOptions({
    layoutName: shiftToggle,
  });
}
